<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select ref="ents" v-model="query.belongTo" placeholder="请选择工厂" filterable :label.sync="query.enterpriseName" url="api/sysEnterprise/children" auto-select-first-option class="filter-item" style="width: 150px;" @change="toQuery" />
      <quick-select v-model="query.enterpriseId" filterable url="api/distributor" :params="{belongTo: query.belongTo}" placeholder="请选择经销商" value-field="enterpriseId" clearable @change="toQuery" class="filter-item" style="width: 300px;" v-if="query.belongTo"/>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="yyyy-MM-dd" :clearable="false" @change="toQuery" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data">
      <!-- <el-table-column type="selection" width="35" reserve-selection fixed /> -->
      <el-table-column prop="erpId" label="客户编码" width="120" fixed />
      <el-table-column prop="name" label="客户名称" min-width="300" show-overflow-tooltip/>
      <!-- <el-table-column prop="enterpriseTypeName" label="企业类型" width="100" /> -->
      <el-table-column label="客户类型" width="160" prop="levelName" />
      <el-table-column prop="areaName" label="所在区域" width="120" />
      <el-table-column prop="shipQuantity" label="发货数量" width="120" align="right" />
      <el-table-column prop="shipAmount" label="发货总额" width="120" align="right" :formatter="$price" />
      <!-- <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" >详情</el-button>
        </div>
      </el-table-column> -->
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {  
  mixins: [initData],
  data() {
    let now = new Date();
    return {
      delLoading: false,
      downloadLoading:false,
      
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },                  
      query: {     
        enterpriseId:null,   
        dateRange: [
          new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate()
          ),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          ),
        ],
      },
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderSendForm/summary";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.startDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: "createAt,desc" }, query);
      return true;
    },
    toDownload() {
      if (!this.data || (this.data && this.data.length === 0)) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.page = 0;
      this.params.size = this.total;
      download("api/orderSendForm/summary/download", this.params)
        .then((result) => {
          downloadFile(result, "工厂发货汇总", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>